import { useEffect } from 'react';
import { useLocation} from "react-router";

export const ScrollToTopController = () => {
    const location = useLocation();
    useEffect(() => {
        try {
            window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth"
            });
        } catch (error) {
            window.scrollTo(0, 0);
        }
    }, [location]);
    return null;
};